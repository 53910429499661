import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
const Whiteglove = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>White Glove Movers</h2>
        <p>
         For the discerning client
        </p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section>
            <header>
              <h4>What's white glove service?</h4>
              <p>
              White glove moving is more than just a pair of gloves. And it turns out it's a service that fits for more people than you'd expect.
              </p>
            </header>
            <header>
              <h4>Our white glove service is the premium choice if you need movers in San Diego</h4>
              <p>
                Included in the white glove service is everything that's included in the <a href = "/fullpacking"> full packing movers </a> package and then some. With the white glove service the
                movers will first lay down runners, and tarps. The footboards get taped up to keep them protected during the move. Quilted moving pads will be secure on the stairways, railings, and any other surfaces we see neccessary.
                All of the runners and pads will be taken down once everything is safely loaded on to the truck. All of the items on the truck in addition to being tied down with ratchets and ties, will be shrink wrapped in place to ensure that everything stays perfectly still. And again on
                the delivery end the movers will lay down runners, and tarps before getting started to make sure that your place is clean and new when they are done moving everything in.
              </p>
              <h4>Optional with the white glove service</h4>
              <p>With the white glove service we offer discounted rates on sole trucks so if you would like a dedicated semi truck just for you we can get you a great deal. </p>


            </header>
          </section>
        </div>
      </section>


    </article>
  </Layout>
);
export default Whiteglove;
